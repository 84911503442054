import { useSpring } from 'react-spring';
import './App.css';
import ChangeClothesMainPage from './Change_Clothes/ChangeClothesMainPage';
import UseSpring from './Change_Clothes/UseSpring';

function App() {
  return (
    <div className="">

      <ChangeClothesMainPage>

      </ChangeClothesMainPage>


      {/* <UseSpring></UseSpring> */}
    </div>
  );
}

export default App;
